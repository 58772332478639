import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/emr/components/PageContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/emr/components/UpdateCheck.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/app/apps/emr/context/AppProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/emr/context/ConfirmProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/emr/context/LocalizationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/emr/context/SnackbarProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/emr/context/ThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/emr/datadog-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCProvider"] */ "/app/apps/emr/trpc/TRPCProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
