import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { AppContext } from 'context/AppProvider';
import { usePathname, useRouter } from 'navigation';
import { useContext, type JSX } from 'react';

export type DrawerContent = {
  title: string;
  icon: JSX.Element;
  route: string;
  countIndex?: string;
};

const DrawerContents = ({
  open,
  contents,
  onSelect,
}: // context,
{
  open?: boolean;
  contents: DrawerContent[];
  onSelect?: any;
  // context: any;
}) => {
  const router = useRouter();
  const theme = useTheme();
  const pathname = usePathname();
  const { drawerOpen, setDrawerToggle } = useContext(AppContext);

  const handleClick = (route: string) => {
    if (onSelect) {
      onSelect();
    }

    if (drawerOpen && setDrawerToggle) {
      setDrawerToggle();
    }

    router.push(route);
  };

  return (
    <List sx={{ flexGrow: 1 }}>
      {contents.map((item) => (
        <ListItem
          key={item.title}
          disablePadding
          onClick={() => handleClick(item.route)}
          data-testid={item.title}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              '&:hover': { backgroundColor: theme.palette.primary.light },
            }}
            selected={pathname === item.route}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                color: theme.palette.text.primary,
              }}
            >
              {item.icon}
            </ListItemIcon>
            {open && (
              <ListItemText
                primary={item.title}
                sx={{ color: theme.palette.text.primary, opacity: 1 }}
              />
            )}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default DrawerContents;
