import { createNavigation } from 'next-intl/navigation';
import { defineRouting } from 'next-intl/routing';

export const locales = ['en'];

export const routing = defineRouting({
  locales,
  localePrefix: 'as-needed',
  defaultLocale: 'en',
});

export const { Link, getPathname, redirect, usePathname, useRouter } = createNavigation(routing);
