import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PeopleIcon from '@mui/icons-material/People';
import { Role } from '@cp/database/types';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MasksIcon from '@mui/icons-material/Masks';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PaidIcon from '@mui/icons-material/Paid';

import type { JSX } from 'react';

type DrawerItem = {
  title: string;
  icon: JSX.Element;
  route: string;
};

const baseDrawerItems: DrawerItem[] = [
  {
    title: 'Home',
    icon: <HomeIcon />,
    route: '/dashboard',
  },
  {
    title: 'Patients',
    icon: <FamilyRestroomIcon />,
    route: '/patients',
  },
];

const adminDrawerItems: DrawerItem[] = [
  {
    title: 'Appointments',
    icon: <CalendarMonthIcon />,
    route: '/appointments',
  },
  {
    title: 'Payers',
    icon: <PaidIcon />,
    route: '/payers',
  },
  {
    title: 'Providers',
    icon: <MasksIcon />,
    route: '/providers',
  },
  {
    title: 'Referrals',
    icon: <AssignmentIcon />,
    route: '/referrals',
  },
  {
    title: 'Reminders',
    icon: <NotificationsActiveIcon />,
    route: '/reminders',
  },
  {
    title: 'Services',
    icon: <MedicalServicesIcon />,
    route: '/services',
  },
  {
    title: 'Settings',
    icon: <SettingsIcon />,
    route: '/settings',
  },
  {
    title: 'Users',
    icon: <PeopleIcon />,
    route: '/users',
  },
];

const providerDrawerItems: DrawerItem[] = [
  {
    title: 'Referrals',
    icon: <AssignmentIcon />,
    route: '/referrals',
  },
];

const getDrawerItems = (role: string) => {
  const drawerItems = baseDrawerItems;

  if (role === Role.admin) {
    return drawerItems.concat(adminDrawerItems).sort((a, b) => a.title.localeCompare(b.title));
  }

  if (role === Role.provider) {
    return drawerItems.concat(providerDrawerItems).sort((a, b) => a.title.localeCompare(b.title));
  }

  return drawerItems;
};

export { getDrawerItems };
